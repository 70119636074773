/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import logoDev from "../../../../resources/images/logodev.png";
import Header from "../../../widget/index";
import Financial from "./financial/financial";
import Overview from "./overview/overview";
import Pitch from "./pitch/pitch";
import Strategy from "./strategy/strategy";
/* for Edit*/
import * as BController from "../../../../controller/company-ctr/profil/block-profil-company-controller/index";
import UseProfileCompanyPageCtr from "../../../../controller/company-ctr/profil/use-profile-company-page-ctr";
import { ReactComponent as Loader } from "../../../../resources/images/Rolling.svg";
import ModalPhoto from "../../../widget/modal/modal-photo";
import UseModalPhoto from "../../../widget/modal/use-modal-photo";
import SoftwareSolution from "./edit-basic-information/software-solution";

import { Link } from "react-scroll";
import * as T from "../../../../controller/company-ctr/types/types-company-page";
import { HIDDEN_INFORMATION } from "../../../../service/constant";
import {
  fileUrl,
  refaCtoNullKey,
  uppercaseFirstLetter,
} from "../../../../utils";
import Footer from "../../../widget/footer/footer";
import { LoadingAllPage } from "../../../widget/loader";
import PlanDetails from "../../../widget/manage-company-and-billing/index";
import AffiliatedInsider from "../../../widget/manage-company-and-billing/roles/affiliated-insider";
import UseAffilliated from "../../../widget/manage-company-and-billing/roles/use-affilliated";
import UseManageCompanyAndBilling from "../../../widget/manage-company-and-billing/use-manage-company-and-billing";
import BackGroundCompany from "./bloc-company/back-ground-company";
import CircularVeiew from "./circular-view/index";
import HeaderTab from "./header-tab/index";
import InfoCompany from "./info-company/index";
import InfoSoftwareSolution from "./info-software-solution/index";
import InfoTargetedIndustry from "./info-targeted-industry/index";
import ModalEditBasicInfo from "./modal-edit-bloc-company/edit-company-information";
import ModalEditTargetIndustry from "./modal-edit-bloc-company/edit-target-industry";
import RenderPercent from "../../find-isv-matching/bloc-find-isv/render-percent";
import { getAffiliatedMembers } from "../../../../service/applicatif/acount";

export default function ProfilIndex() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isUpdatePage, setIsUpdatePage] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const providerUseProfileCompanyPageCtr: any = {
    ...UseProfileCompanyPageCtr(),
  };

  const providerUseUserInfoCtr = { ...BController.UseUserInfoCtr() };

  const providerUsePitch = { ...BController.usePitch() };

  const providerUseSoftwareSolutionCtr = {
    ...BController.UseSoftwareSolutionCtr(),
  };
  const providerUseTargetIndustrieCtr = {
    ...BController.UseTargetIndustrieCtr(),
  };
  const providerUseOverview = { ...BController.UseOverview() };

  const providerUseGeographieTarget = {
    ...BController.UseGeographieTarget(),
  };
  const provierUseFinancialCtr = { ...BController.UseFinancialCtr() };

  const providerUseLicencingMDistributionC = {
    ...BController.UseLicencingModelDistributionChannel(),
  };

  const providerUseManageCompanyAndBilling = UseManageCompanyAndBilling();
  const providerUseModalPhoto = UseModalPhoto();

  const [tabNavigate, setTabNavigate] = useState("");

  const providerUseAffilliated = UseAffilliated();

  const isEmptyGraphValue = (current: any) => {
    return current?.amount !== "";
  };

  const isUnlockOver =
    window?.location?.pathname.includes("/company-unlocked/");

  const adminCloseAccount =
    providerUseProfileCompanyPageCtr?.companyPageObject?.has_closed_company;

  /* 
    Si le pourcentage est supérieur à 100% on remet à 100%
  */
  let totalPercentLimitOtherCompany: number =
    providerUseProfileCompanyPageCtr?.configOtherCompany?.matching;

  if (typeof totalPercentLimitOtherCompany === "number") {
    if (totalPercentLimitOtherCompany > 100) {
      totalPercentLimitOtherCompany = 100;
    }
    if (isUnlockOver && adminCloseAccount) {
      totalPercentLimitOtherCompany = 59;
    }
  }

  const isCompletedPitch =
    providerUseProfileCompanyPageCtr?.companyPageObject?.totalProgress >= 95 &&
    providerUseProfileCompanyPageCtr?.companyPageObject?.description === "" &&
    !providerUseProfileCompanyPageCtr.idOtherUser;

  useEffect(() => {
    setIsUpdatePage(
      !!(
        (isUnlockOver && adminCloseAccount) ||
        providerUseProfileCompanyPageCtr?.companyPageObject?.showUpdatedFlag
      )
    );
  }, [
    providerUseProfileCompanyPageCtr?.companyPageObject?.showUpdatedFlag,
    isUnlockOver,
    adminCloseAccount,
  ]);

  const [isvInCompanyPage, setIsvInCompanyPage] = useState<Array<any>>([]);

  useEffect(() => {
    async function getAffilliated() {
      const result = await getAffiliatedMembers(
        providerUseProfileCompanyPageCtr?.companyPageObject?.id,
        providerUseProfileCompanyPageCtr?.userObject.token
      );
      if (result?.status === 200) {
        setIsvInCompanyPage(result?.data?.users);
      }
    }

    const allowGetAffiliated =
      !providerUseProfileCompanyPageCtr.idOtherUser &&
      providerUseProfileCompanyPageCtr?.companyPageObject?.id;
    if (allowGetAffiliated) {
      getAffilliated();
    }
  }, []);

  return (
    <>
      {providerUseProfileCompanyPageCtr &&
        providerUseProfileCompanyPageCtr.isLoadingCompanyInfo && (
          <LoadingAllPage />
        )}
      {providerUseProfileCompanyPageCtr &&
        !providerUseProfileCompanyPageCtr.isLoadingCompanyInfo && (
          <div className="bggris pb-0">
            <Header />
            {isUpdatePage && (
              <div
                className="alert alert-warning alert-dismissible fade show"
                role="alert"
              >
                The content of this company page has been modified by its admin.
                Content that was available at the point of unlocking the page
                may have been changed or deleted.
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setIsUpdatePage(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            )}
            <section
              className={`companypage ${
                providerUseOverview?.editProfilCompany ? "editPageCompany" : ""
              }`}
            >
              <div className="container mx-auto">
                <BackGroundCompany
                  setIsShowUpdateBackGroundPicture={
                    providerUseModalPhoto.setIsShowUpdateBackGroundPicture
                  }
                  setIsShowUpdateProfil={
                    providerUseModalPhoto.setIsShowUpdateProfil
                  }
                  showPopupRemoveLogo={
                    providerUseModalPhoto.showPopupRemoveLogo
                  }
                  setShowPopupRemoveLogo={
                    providerUseModalPhoto.setShowPopupRemoveLogo
                  }
                  showPopupRemoveCouverture={
                    providerUseModalPhoto.showPopupRemoveCouverture
                  }
                  setShowPopupRemoveCouverture={
                    providerUseModalPhoto.setShowPopupRemoveCouverture
                  }
                  handleRemoveLogoCompanyPage={
                    providerUseModalPhoto.handleRemoveLogoCompanyPage
                  }
                  handleRemoveCouvertureCompanyPage={
                    providerUseModalPhoto.handleRemoveCouvertureCompanyPage
                  }
                  processingRemovePhoto={
                    providerUseModalPhoto.processingRemovePhoto
                  }
                />
                <div className="row m-0 p-0 mb-4 columnOfCompany">
                  <div className="col-xl-4 col-lg-3  mini-column">
                    {!providerUseProfileCompanyPageCtr.idOtherUser && (
                      <CircularVeiew />
                    )}

                    {isCompletedPitch && (
                      <div className="completedCompany d-flex align-items-center p-2">
                        <div className="photo">
                          <img src={logoDev} alt="" />
                        </div>
                        <div className="desc">
                          <div>
                            {/* Dev #47635 Bug sur la company page concernant le % de remplissage */}
                            <b>Congratulations! </b>Your page is{" "}
                            {
                              providerUseProfileCompanyPageCtr
                                ?.companyPageObject?.totalProgress
                            }
                            % <br />
                            completed. To grab the attention <br />
                            of investors and fully complete your company page,
                            write your{" "}
                            <Link
                              to="#"
                              onClick={() => {
                                providerUsePitch?.setIsShowPitch(true);
                              }}
                            >
                              Devinsider pitch.
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                    {providerUseProfileCompanyPageCtr.idOtherUser &&
                      providerUseProfileCompanyPageCtr.companyPageObject
                        .company_name !== HIDDEN_INFORMATION && (
                        <>
                          {totalPercentLimitOtherCompany < 60 ? (
                            <RenderPercent
                              isUnlock={true}
                              number={totalPercentLimitOtherCompany}
                            />
                          ) : (
                            <div className="matchValue m90">
                              {providerUseProfileCompanyPageCtr?.configOtherCompany &&
                                `${totalPercentLimitOtherCompany}% Match`}
                            </div>
                          )}
                          <div
                            className="contact"
                            onClick={
                              !adminCloseAccount
                                ? providerUseProfileCompanyPageCtr.contactIsv
                                : null
                            }
                          >
                            <a
                              className="btn btn-devinsider px-5 save"
                              style={{
                                cursor: adminCloseAccount && "not-allowed",
                              }}
                            >
                              Contact
                            </a>
                          </div>
                          <div className="favorites">
                            <a
                              className="btn-devinsider px-5 cancel"
                              onClick={
                                providerUseProfileCompanyPageCtr.addFavorite
                              }
                            >
                              <span
                                className={`${
                                  providerUseProfileCompanyPageCtr
                                    ?.configOtherCompany?.is_favorite
                                    ? "icon-star-on"
                                    : "icon-star-off"
                                } star`}
                              ></span>
                              Favorites
                            </a>
                            <a
                              className="btn-devinsider px-5 cancel"
                              onClick={
                                providerUseProfileCompanyPageCtr.backResultPanel
                              }
                            >
                              Back to the results panel
                            </a>
                          </div>
                        </>
                      )}

                    <InfoCompany
                      showEditInfo={() =>
                        providerUseUserInfoCtr?.setShowRequestBasicInformation(
                          true
                        )
                      }
                    />
                    <InfoSoftwareSolution
                      isShowEdit={() =>
                        providerUseSoftwareSolutionCtr?.setShowRequestSoftwareSolution(
                          true
                        )
                      }
                    />

                    <InfoTargetedIndustry
                      setShowEdit={() =>
                        providerUseTargetIndustrieCtr?.setShowRequestTargetIndustry(
                          true
                        )
                      }
                    />
                    {!adminCloseAccount && (
                      <div className="containerLeftItem py-3">
                        <a
                          className="linkEditLeftCompany"
                          onClick={() =>
                            providerUseAffilliated?.setShowRequestAffiliatedInsider(
                              true
                            )
                          }
                        >
                          Edit
                          <span className="icon-edit-full"></span>
                        </a>
                        {/* //Dev #48042,V2.1 [Investor] Preview Mode of an ISV company profile */}
                        {isvInCompanyPage?.length > 0 && (
                          <h2 className="title">Affiliated insiders</h2>
                        )}

                        <ul className="listAffiliatedleft">
                          {isvInCompanyPage?.map(
                            (el: T.TitemSentAaffiliated, index: number) => (
                              <li key={index}>
                                <div className="imageAffiliatedcompany">
                                  <img
                                    src={fileUrl(el.user.file_name)}
                                    alt=""
                                  />
                                </div>
                                <div className="infoAffiliated">
                                  <h3 className="title pointer">
                                    {el?.user?.first_name} -{" "}
                                    {uppercaseFirstLetter(el?.role)}
                                  </h3>
                                  <div className="post">
                                    {refaCtoNullKey(el?.user?.job_title)}
                                    {el?.user?.user_community_profil !==
                                    "VISIBLE"
                                      ? ""
                                      : " at " +
                                        refaCtoNullKey(
                                          providerUseProfileCompanyPageCtr
                                            .companyPageObject.company_name
                                        )}
                                  </div>
                                  <div className="adress">
                                    {el?.user?.country?.country_name}
                                    {refaCtoNullKey(el?.user?.city) === ""
                                      ? ""
                                      : ", " + refaCtoNullKey(el?.user?.city)}
                                  </div>
                                </div>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="col-xl-8 col-lg-9 maxi-column p-0 m-0">
                    {isUnlockOver && adminCloseAccount ? (
                      <div className="alert alert-warning" role="alert">
                        The admin account of this company page has been deleted.
                        While basic company information is still available, this
                        page is not actively managed.
                      </div>
                    ) : (
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="overview"
                      >
                        <HeaderTab
                          isAdmin={
                            providerUseProfileCompanyPageCtr?.userObject
                              ?.isvInCompanyPage?.role === "ADMIN"
                          }
                          isOtherUser={
                            providerUseProfileCompanyPageCtr.idOtherUser
                          }
                          setEditProfilCompany={() =>
                            providerUseOverview?.setEditProfilCompany(
                              !providerUseOverview?.editProfilCompany
                            )
                          }
                          setShowManageCompanyPage={() =>
                            providerUseManageCompanyAndBilling?.setShowBillingCenter(
                              true
                            )
                          }
                          setTabNavigate={(p: string) => setTabNavigate(p)}
                        />
                        <div
                          className={`prof secondHeader ${
                            providerUseProfileCompanyPageCtr.idOtherUser
                              ? ""
                              : "d-none"
                          } ${scrollPosition < 50 ? "" : "fixedSecondHeader"}`}
                        >
                          <div className="container mx-auto">
                            <div className="title col-xl-4 col-lg-3">
                              {/* <h5>Title</h5> */}
                            </div>
                            <div className="menuAnchor col-xl-8 col-lg-9 maxi-column p-0 m-0">
                              <div className="nav nav-pills">
                                <div className="nav-item">
                                  <Link
                                    activeClass="active"
                                    to="left-tabs-example-tabpane-overview"
                                    spy={true}
                                    smooth={true}
                                    duration={1000}
                                    className="anchorOverview"
                                    offset={-140}
                                  >
                                    Overview
                                  </Link>
                                </div>
                                <div className="nav-item">
                                  <Link
                                    activeClass="active"
                                    to="left-tabs-example-tabpane-strategy"
                                    spy={true}
                                    smooth={true}
                                    duration={1000}
                                    offset={-140}
                                  >
                                    Strategy
                                  </Link>
                                </div>
                                <div className="nav-item">
                                  <Link
                                    activeClass="active"
                                    to="left-tabs-example-tabpane-financial"
                                    spy={true}
                                    smooth={true}
                                    duration={1000}
                                    offset={-140}
                                  >
                                    Financial
                                  </Link>
                                </div>
                                {(!providerUseProfileCompanyPageCtr?.idOtherUser ||
                                  providerUseProfileCompanyPageCtr.isInvestor) && (
                                  <div className="nav-item">
                                    <Link
                                      activeClass="active"
                                      to="left-tabs-example-tabpane-Pitch"
                                      spy={true}
                                      smooth={true}
                                      duration={1000}
                                      offset={-140}
                                    >
                                      Pitch
                                    </Link>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`${
                            providerUseProfileCompanyPageCtr.idOtherUser
                              ? "anchor contentCompanyTab"
                              : "contentCompanyTab"
                          }`}
                        >
                          <Tab.Content>
                            <Tab.Pane eventKey="overview">
                              <Overview
                                // Dev #47679 V1 Agrandir la taille max de la video dans company page
                                uploadVideoStatus={
                                  providerUseOverview?.uploadVideoStatus
                                }
                                handleModalRemoveVideo={() =>
                                  providerUseOverview?.handleModalRemoveVideo()
                                }
                                handleRemoveVideo={() =>
                                  providerUseOverview?.handleRemoveVideo()
                                }
                                handleModalVideo={() =>
                                  providerUseOverview?.handleModalVideo()
                                }
                                showRemovePopupVideo={
                                  providerUseOverview?.showRemovePopupVideo
                                }
                                setShowRemovePopupVideo={
                                  providerUseOverview?.setShowRemovePopupVideo
                                }
                                processingRemoveVideo={
                                  providerUseOverview?.processingRemoveVideo
                                }
                                summaryCompany={
                                  providerUseProfileCompanyPageCtr
                                    ?.companyPageObject.company_summary
                                }
                                modalValueSummary={
                                  providerUseOverview?.modalValueSummary
                                }
                                setModalValueSummary={(value) =>
                                  providerUseOverview?.setModalValueSummary(
                                    value
                                  )
                                }
                                summaryLength={
                                  providerUseOverview?.summaryLength
                                }
                                setSummaryLength={(value) =>
                                  providerUseOverview?.setSummaryLength(value)
                                }
                                updateCompanySummary={
                                  providerUseOverview?.updateCompanySummary
                                }
                                isLoader={providerUseOverview?.loaderOverView}
                                isShowOverView={
                                  providerUseOverview?.showOverview
                                }
                                setIsShowOverView={(params: boolean) =>
                                  providerUseOverview?.setShowOverview(params)
                                }
                                editProfilCompany={
                                  providerUseOverview?.editProfilCompany
                                }
                                isShowVideoEdit={
                                  providerUseOverview?.isShowVideoEdit
                                }
                                setIsShowVideoEdit={(p: boolean) =>
                                  providerUseOverview?.setIsShowVideoEdit(p)
                                }
                                fileRef={providerUseOverview?.fileRef}
                                changeHandler={(p: any) =>
                                  providerUseOverview?.changeHandler(p)
                                }
                                handleBtn={() =>
                                  providerUseOverview?.handleBtn()
                                }
                                isFilePicked={providerUseOverview?.isFilePicked}
                                selectedFile={providerUseOverview?.selectedFile}
                                updateVideoPresentation={() =>
                                  providerUseOverview?.updateVideoPresentation()
                                }
                                isShowLoaderUpdateVideo={
                                  providerUseOverview?.isShowLoaderUpdateVideo
                                }
                                filePresentation={
                                  providerUseProfileCompanyPageCtr
                                    ?.companyPageObject?.video_profile
                                }
                                addForm={(
                                  p?: T.TitemSentAaffiliated,
                                  index?: number
                                ) => providerUseOverview?.addForm(p, index)}
                                listLeaderShipForm={
                                  providerUseOverview?.listLeaderShipForm
                                }
                                isShowLeaderShip={
                                  providerUseOverview?.isShowLeaderShip
                                }
                                setIsShowLeaderShip={(p: boolean) =>
                                  providerUseOverview?.setIsShowLeaderShip(p)
                                }
                                changeInputLeaderShip={(p: T.TleaderShipTeam) =>
                                  providerUseOverview?.changeInputLeaderShip(p)
                                }
                                saveLeaderShip={() =>
                                  providerUseOverview?.saveLeaderShip()
                                }
                                changeHandlerLeaderShip={(
                                  file: any,
                                  index: number,
                                  indexInForm: number
                                ) =>
                                  providerUseOverview?.changeHandlerLeaderShip(
                                    file,
                                    index,
                                    indexInForm
                                  )
                                }
                                leaderShipList={
                                  providerUseProfileCompanyPageCtr
                                    ?.companyPageObject
                                    ?.companyPageLeadershipTeams
                                }
                                isShowLoaderLeaderShip={
                                  providerUseOverview?.isShowLoaderLeaderShip
                                }
                                affiliatedList={
                                  providerUseProfileCompanyPageCtr
                                    ?.companyPageObject?.isvInCompanyPage
                                }
                                statisticOverView={
                                  providerUseProfileCompanyPageCtr
                                    ?.companyPageObject?.progressOverview
                                }
                                valueOfPitch={
                                  providerUseProfileCompanyPageCtr
                                    ?.companyPageObject?.description
                                }
                                cancelFormLeaderShip={() =>
                                  providerUseOverview?.cancelFormLeaderShip()
                                }
                                cancelUpdateSummary={() =>
                                  providerUseOverview?.cancelUpdateSummary()
                                }
                                removeLeaderShip={(
                                  p?: string,
                                  idForm?: string
                                ) =>
                                  providerUseOverview?.removeLeaderShip(
                                    p,
                                    idForm
                                  )
                                }
                                handleDrop={(p: any) =>
                                  providerUseOverview?.handleDrop(p)
                                }
                                errorsFields={
                                  providerUseOverview?.isShowErrorField
                                }
                                isShowMailInvite={
                                  providerUseOverview?.isShowMailInvite
                                }
                                checkEmailAction={(evt: string) =>
                                  providerUseOverview?.checkEmailAction(evt)
                                }
                                safeEmail={providerUseOverview?.safeEmail}
                                txtEmailError={
                                  providerUseOverview?.txtEmailError
                                }
                              />
                            </Tab.Pane>
                            <Tab.Pane eventKey="strategy">
                              <Strategy
                                licengingModel={
                                  providerUseProfileCompanyPageCtr
                                    ?.companyPageObject?.licensing_model
                                }
                                statisticStrategy={
                                  providerUseProfileCompanyPageCtr
                                    ?.companyPageObject?.progressStrategy
                                }
                                licengingModelData={
                                  providerUseProfileCompanyPageCtr
                                    ?.dataCompleted?.licensing_model
                                }
                                licenCingModelSelected={
                                  providerUseLicencingMDistributionC?.licenCingModelSelected
                                }
                                distributionModelSelected={
                                  providerUseLicencingMDistributionC?.distributionModelSelected
                                }
                                addLicencingChecked={(id: number) =>
                                  providerUseLicencingMDistributionC?.addLicencingChecked(
                                    id
                                  )
                                }
                                addDistributionChannel={(id: number) =>
                                  providerUseLicencingMDistributionC?.addDistributionChannel(
                                    id
                                  )
                                }
                                isShowLicencingModel={
                                  providerUseLicencingMDistributionC?.isShowLicencingModel
                                }
                                setIsShowLicencingModel={(param: boolean) =>
                                  providerUseLicencingMDistributionC?.setIsShowLicencingModel(
                                    param
                                  )
                                }
                                loader={
                                  providerUseProfileCompanyPageCtr?.loader
                                }
                                loaderTargetCountry={
                                  providerUseGeographieTarget?.loaderTargetCountry
                                }
                                updateLicencingModel={
                                  providerUseLicencingMDistributionC?.updateLicencingModel
                                }
                                distributionChannel={
                                  providerUseProfileCompanyPageCtr
                                    ?.companyPageObject?.distribution_channel
                                }
                                partenerShipinterests={
                                  providerUseProfileCompanyPageCtr
                                    ?.companyPageObject
                                    ?.oem_program_segment_interest
                                }
                                oemProgramSegmentInterestText={
                                  providerUseProfileCompanyPageCtr
                                    ?.companyPageObject
                                    ?.oemProgramSegmentInterestText
                                }
                                geographieTarget={providerUseGeographieTarget?.flatArrayWithSameId(
                                  providerUseProfileCompanyPageCtr
                                    ?.companyPageObject.targeted_country
                                )}
                                selectedStategieInPlace={
                                  providerUseProfileCompanyPageCtr
                                    ?.companyPageObject?.partners
                                }
                                typeOfOffice={
                                  providerUseProfileCompanyPageCtr
                                    ?.companyPageObject?.type_of_office
                                }
                                index3={
                                  providerUseProfileCompanyPageCtr?.index3
                                }
                                setIndex3={(i: number) =>
                                  providerUseProfileCompanyPageCtr?.setindex3(i)
                                }
                                editProfilCompany={
                                  providerUseOverview?.editProfilCompany
                                }
                                distributionModelData={
                                  providerUseProfileCompanyPageCtr
                                    ?.dataCompleted?.distribution_channel
                                }
                                allContinents={
                                  providerUseProfileCompanyPageCtr
                                    ?.dataCompleted?.targetedCountry
                                }
                                paysChecked={
                                  providerUseGeographieTarget?.paysChecked
                                }
                                addPaysInArray={
                                  providerUseGeographieTarget?.addPaysInArray
                                }
                                allContinentSelected={
                                  providerUseGeographieTarget?.allContinentSelected
                                }
                                isShowOfficeData={
                                  providerUseGeographieTarget?.isShowOfficeData
                                }
                                addOffice={
                                  providerUseGeographieTarget?.addOffice
                                }
                                selectedOffice={
                                  providerUseGeographieTarget?.selectedOffice
                                }
                                dataCompleted={
                                  providerUseProfileCompanyPageCtr?.dataCompleted
                                }
                                updateModalGeographicTarget={() =>
                                  providerUseGeographieTarget?.updateModalGeographicTarget()
                                }
                                showStrategyGeographic={
                                  providerUseGeographieTarget?.showStrategyGeographic
                                }
                                setshowStrategyGeographic={(params: boolean) =>
                                  providerUseGeographieTarget?.setshowStrategyGeographic(
                                    params
                                  )
                                }
                                technologiesPartnership={
                                  providerUseProfileCompanyPageCtr?.technologiesPartnership
                                }
                                addListOption={
                                  providerUseProfileCompanyPageCtr?.addListOption
                                }
                                addParterShip={
                                  providerUseProfileCompanyPageCtr?.addParterShip
                                }
                                removeSelected={
                                  providerUseProfileCompanyPageCtr?.removeSelected
                                }
                                setCurrentSelected={
                                  providerUseProfileCompanyPageCtr?.setCurrentSelected
                                }
                                checkedSoftware={
                                  providerUseProfileCompanyPageCtr?.checkedSoftware
                                }
                                setCheckedSoftware={(p: string) =>
                                  providerUseProfileCompanyPageCtr?.setCheckedSoftware(
                                    p
                                  )
                                }
                                partenerShipSelected={
                                  providerUseProfileCompanyPageCtr?.partenerShipSelected
                                }
                                currentSelected={
                                  providerUseProfileCompanyPageCtr?.currentSelected
                                }
                                currentSelectedList={
                                  providerUseProfileCompanyPageCtr?.currentSelectedList
                                }
                                updateTechnologiePartener={() =>
                                  providerUseProfileCompanyPageCtr?.updateTechnologiePartener()
                                }
                                isShowPartenerShipModal={
                                  providerUseProfileCompanyPageCtr?.isShowPartenerShipModal
                                }
                                setisShowPartenerShipModal={(params: boolean) =>
                                  providerUseProfileCompanyPageCtr?.setisShowPartenerShipModal(
                                    params
                                  )
                                }
                                loaderlicencingModel={
                                  providerUseLicencingMDistributionC?.loaderlicencingModel
                                }
                                isHaveError={
                                  providerUseLicencingMDistributionC?.isHaveErrorCategorie
                                }
                                cancelUpdateCategorie={() =>
                                  providerUseLicencingMDistributionC?.cancelUpdateCategorie()
                                }
                                cancelGeoGraphieTarget={() =>
                                  providerUseGeographieTarget?.cancelGeoGraphieTarget()
                                }
                                isShowErroCountrySelected={
                                  providerUseGeographieTarget?.isShowErroCountrySelected
                                }
                                isShowErrorNorthAmerica={
                                  providerUseGeographieTarget?.isShowErrorNorthAmerica
                                }
                                cancelTechnologieParteners={() =>
                                  providerUseProfileCompanyPageCtr?.cancelTechnologieParteners()
                                }
                                isShowErrorPartenerShip={
                                  providerUseProfileCompanyPageCtr?.isShowErrorPartenerShip
                                }
                              />
                            </Tab.Pane>
                            <Tab.Pane eventKey="financial">
                              <Financial
                                amontWouldLike={
                                  providerUseProfileCompanyPageCtr
                                    ?.companyPageObject?.amount_want_to_raise ||
                                  ""
                                }
                                statisticFinancial={
                                  providerUseProfileCompanyPageCtr
                                    ?.companyPageObject?.progressFinancial
                                }
                                annualTurn={
                                  providerUseProfileCompanyPageCtr
                                    ?.companyPageObject?.annual_turnover
                                }
                                fundingRound={
                                  providerUseProfileCompanyPageCtr
                                    ?.companyPageObject?.funding_round
                                }
                                annualTurnoverData={
                                  providerUseProfileCompanyPageCtr
                                    ?.dataCompleted?.annual_turnover
                                }
                                editProfilCompany={
                                  providerUseOverview?.editProfilCompany
                                }
                                isShowFinancialAnnual={
                                  provierUseFinancialCtr?.isShowFinancialAnnual
                                }
                                setIsShowFinancialAnnual={(params: boolean) =>
                                  provierUseFinancialCtr?.setIsShowFinancialAnnual(
                                    params
                                  )
                                }
                                isShowModalFundingRoundAndAmount={
                                  provierUseFinancialCtr?.isShowModalFundingRoundAndAmount
                                }
                                setIsShowModalFundingRoundAndAmount={(
                                  params: boolean
                                ) =>
                                  provierUseFinancialCtr?.setIsShowModalFundingRoundAndAmount(
                                    params
                                  )
                                }
                                annualTurnOverSelected={
                                  provierUseFinancialCtr?.annualTurnOverSelected
                                }
                                setAnnulTurnover={(params: string) =>
                                  provierUseFinancialCtr?.setAnnulTurnover(
                                    params
                                  )
                                }
                                updateAnnualTurnOver={() =>
                                  provierUseFinancialCtr?.updateAnnualTurnOver()
                                }
                                loaderFinancial={
                                  provierUseFinancialCtr?.loaderFinancial
                                }
                                fundingRoundValue={
                                  provierUseFinancialCtr?.fundingRoundValue
                                }
                                setFundingRound={(params: string) =>
                                  provierUseFinancialCtr?.setFundingRoundValue(
                                    params
                                  )
                                }
                                fundingRoundData={
                                  providerUseProfileCompanyPageCtr
                                    ?.dataCompleted?.fundingRound
                                }
                                amontWouldRise={
                                  providerUseProfileCompanyPageCtr
                                    ?.dataCompleted
                                    ?.amount_you_would_like_to_raise
                                }
                                setAmountWouldRiseSelected={(params: any) =>
                                  provierUseFinancialCtr?.setAmountWouldRiseSelected(
                                    params
                                  )
                                }
                                amountWouldRiseSelected={
                                  provierUseFinancialCtr?.amountWouldRiseSelected
                                }
                                setFinancialChart={(p: boolean) =>
                                  provierUseFinancialCtr?.setShowFinancialChart(
                                    p
                                  )
                                }
                                showFinancialChart={
                                  provierUseFinancialCtr?.showFinancialChart
                                }
                                isShowGraph={providerUseProfileCompanyPageCtr?.companyPageObject?.companyPageGraphs.every(
                                  isEmptyGraphValue
                                )}
                                dataGrapH={
                                  providerUseProfileCompanyPageCtr
                                    ?.companyPageObject?.companyPageGraphs
                                }
                                updateChartForm={
                                  provierUseFinancialCtr?.updateChartForm
                                }
                                setUpdateChartForm={(p: Array<T.ItemsGraph>) =>
                                  provierUseFinancialCtr?.setUpdateChartForm(p)
                                }
                                updateGrapheData={() =>
                                  provierUseFinancialCtr?.updateGrapheData()
                                }
                                loaderChart={
                                  provierUseFinancialCtr?.loaderChart
                                }
                                cancelUpdateChart={() =>
                                  provierUseFinancialCtr?.cancelUpdateChart()
                                }
                              />
                            </Tab.Pane>
                            {(!providerUseProfileCompanyPageCtr?.idOtherUser ||
                              providerUseProfileCompanyPageCtr.isInvestor) && (
                              <Tab.Pane eventKey="Pitch">
                                <Pitch
                                  valueOfPitch={
                                    providerUseProfileCompanyPageCtr
                                      ?.companyPageObject?.description
                                  }
                                  statisticPitch={
                                    providerUseProfileCompanyPageCtr
                                      ?.companyPageObject?.progressPitch
                                  }
                                  isShowPitch={providerUsePitch?.isShowPitch}
                                  setIsShowPitch={(params: boolean) =>
                                    providerUsePitch?.setIsShowPitch(params)
                                  }
                                  loader={providerUsePitch?.loaderPitch}
                                  valueOfPitchForm={
                                    providerUsePitch?.valueOfPitchForm
                                  }
                                  setValueOfPitchForm={(param: string) =>
                                    providerUsePitch?.setValueOfPitchForm(param)
                                  }
                                  isShowPitchPoint={
                                    providerUsePitch?.isShowPitchPoint
                                  }
                                  setIsShowPitchPoint={(param: boolean) =>
                                    providerUsePitch?.setIsShowPitchPoint(param)
                                  }
                                  pitchPointValueForm={
                                    providerUsePitch?.pitchPointValueForm
                                  }
                                  setPitchPointValueForm={(param: string) =>
                                    providerUsePitch?.setPitchPointValueForm(
                                      param
                                    )
                                  }
                                  valueOfPitchPoint={
                                    providerUseProfileCompanyPageCtr
                                      ?.companyPageObject?.paint_point_pitch
                                  }
                                  isShowKeyCompetitive={
                                    providerUsePitch?.isShowKeyCompetitive
                                  }
                                  setIsShowKeyCompetitive={(params: boolean) =>
                                    providerUsePitch?.setIsShowKeyCompetitive(
                                      params
                                    )
                                  }
                                  valueOfKeyCompetitiveForm={
                                    providerUsePitch?.valueOfKeyCompetitiveForm
                                  }
                                  setValueOfKeyCompetitiveForm={(
                                    params: string
                                  ) =>
                                    providerUsePitch?.setValueOfKeyCompetitiveForm(
                                      params
                                    )
                                  }
                                  valueOfKeyCompetitive={
                                    providerUseProfileCompanyPageCtr
                                      ?.companyPageObject
                                      ?.key_competitive_differentiator_pitch
                                  }
                                  isShowTeamUnique={
                                    providerUsePitch?.isShowTeamUnique
                                  }
                                  setIsShowTeamUnique={(params: boolean) =>
                                    providerUsePitch?.setIsShowTeamUnique(
                                      params
                                    )
                                  }
                                  valueOfTeamUniqueForm={
                                    providerUsePitch?.valueOfTeamUniqueForm
                                  }
                                  setValueOfTeamUniqueForm={(params: string) =>
                                    providerUsePitch?.setValueOfTeamUniqueForm(
                                      params
                                    )
                                  }
                                  valueOfTeamUnique={
                                    providerUseProfileCompanyPageCtr
                                      ?.companyPageObject
                                      ?.team_differentiator_pitch
                                  }
                                  updatePicth={(key?: string) =>
                                    providerUsePitch?.updatePicth(key)
                                  }
                                  isShowModalFile={
                                    providerUsePitch?.isShowModalFile
                                  }
                                  setIsShowModalFile={(params: boolean) =>
                                    providerUsePitch?.setIsShowModalFile(params)
                                  }
                                  fileRef={providerUsePitch?.fileRefDoc}
                                  changeHandler={(p: any) =>
                                    providerUsePitch?.changeHandlerDoc(p)
                                  }
                                  handleBtn={() =>
                                    providerUsePitch?.handleBtnDoc()
                                  }
                                  isFilePicked={
                                    providerUsePitch?.isFilePickedDoc
                                  }
                                  selectedFile={
                                    providerUsePitch?.selectedFileDoc
                                  }
                                  fileName={providerUsePitch?.fileName}
                                  loaderUpdateDoc={
                                    providerUsePitch?.loaderPitch
                                  }
                                  handleDrop={(p: any) =>
                                    providerUsePitch?.handleDropDoc(p)
                                  }
                                  pitchFile={
                                    providerUseProfileCompanyPageCtr
                                      ?.companyPageObject?.pitch_file
                                  }
                                  totalProgress={
                                    providerUseProfileCompanyPageCtr
                                      .companyPageObject?.totalProgress
                                  }
                                  showErrorNotCompleted={() =>
                                    providerUsePitch?.showErrorNotCompleted()
                                  }
                                  isShowNotif={providerUsePitch?.isShowNotif}
                                  setShowNotif={() =>
                                    providerUsePitch?.setShowNotif(
                                      !providerUsePitch?.isShowNotif
                                    )
                                  }
                                  notifNotCompleted={
                                    providerUsePitch?.notifNotCompleted
                                  }
                                  cancelChangeFile={
                                    providerUsePitch?.cancelChangeFile
                                  }
                                />
                              </Tab.Pane>
                            )}
                          </Tab.Content>
                        </div>
                      </Tab.Container>
                    )}
                  </div>
                </div>
              </div>
            </section>

            {/* POPUP basic information */}
            <ModalEditBasicInfo
              show={providerUseUserInfoCtr?.showRequestBasicInformation}
              handleSubmit={(p: any) => providerUseUserInfoCtr?.handleSubmit(p)}
              submitBasicInfo={(p: any) =>
                providerUseUserInfoCtr?.submitBasicInfo(p)
              }
              cancelUpdateform={providerUseUserInfoCtr?.cancelUpdateform}
              register={providerUseUserInfoCtr?.register}
              getValues={providerUseUserInfoCtr?.getValues}
              errors={providerUseUserInfoCtr?.errors}
              loaderProfil={providerUseUserInfoCtr?.loaderProfil}
            />
            {/* END POPUP basic information */}

            {/* POPUP Software solution */}
            <Modal
              show={providerUseSoftwareSolutionCtr?.showRequestSoftwareSolution}
              className="createCompanypopup large forStep5custom"
            >
              <Modal.Header>
                <div className="headerpopupCompany noStep">
                  <h2 className="title">
                    Basic information - Software solution
                  </h2>
                  <a
                    className="close"
                    onClick={() =>
                      providerUseSoftwareSolutionCtr?.cancelUpdateSoftware()
                    }
                  >
                    <span className="icon-close-pop"></span>
                  </a>
                </div>
              </Modal.Header>
              <Modal.Body className="withMinHeight">
                <SoftwareSolution
                  solutionLeftColumnData={
                    providerUseSoftwareSolutionCtr?.solutionLeftColumnData
                  }
                  solutionRigthColumnData={
                    providerUseSoftwareSolutionCtr?.solutionRigthColumnData
                  }
                  solutionChecked={
                    providerUseSoftwareSolutionCtr?.solutionChecked
                  }
                  allSolutionSellected={
                    providerUseSoftwareSolutionCtr?.allSolutionSellected
                  }
                  featuredSolution={
                    providerUseSoftwareSolutionCtr?.featuredSolution
                  }
                  selectSolution={
                    providerUseSoftwareSolutionCtr?.selectSolution
                  }
                  removeItemSelected={
                    providerUseSoftwareSolutionCtr?.removeItemSelected
                  }
                  errorData={providerUseSoftwareSolutionCtr?.isHaveError}
                  allOtherValue={providerUseSoftwareSolutionCtr.allOtherValue}
                  setOtherValueTxt={(data: any, txtOther) =>
                    providerUseSoftwareSolutionCtr.setOtherValueTxt(
                      data,
                      txtOther
                    )
                  }
                />
              </Modal.Body>
              <Modal.Footer>
                <div className="contentBorder formForEmail text-right">
                  <button
                    className="btn btn-devinsider cancel"
                    type="button"
                    onClick={() =>
                      providerUseSoftwareSolutionCtr?.cancelUpdateSoftware()
                    }
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-devinsider px-5 save"
                    onClick={() =>
                      providerUseSoftwareSolutionCtr?.updateSoftWareSolution()
                    }
                    disabled={
                      providerUseSoftwareSolutionCtr?.loaderSoftWareSolution
                    }
                  >
                    {providerUseSoftwareSolutionCtr?.loaderSoftWareSolution ? (
                      <Loader />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
            {/* END POPUP Software solution */}

            {/* POPUP Target industry */}
            <ModalEditTargetIndustry
              show={providerUseTargetIndustrieCtr.show}
              cancelUpdataTargeted={
                providerUseTargetIndustrieCtr.cancelUpdataTargeted
              }
              updateTargetIndustrie={
                providerUseTargetIndustrieCtr.updateTargetIndustrie
              }
              loaderTargetIndustrie={
                providerUseTargetIndustrieCtr.loaderTargetIndustrie
              }
              providerUseTargetIndustrieCtr={providerUseTargetIndustrieCtr}
            />
            {/* END POPUP Target industry*/}

            {/* POPUP Affiliated insider */}
            <Modal
              show={providerUseAffilliated?.showRequestAffiliatedInsider}
              className="createCompanypopup large forStep5custom"
            >
              <Modal.Header>
                <div className="headerpopupCompany noStep">
                  <h2 className="title">Affiliated insiders</h2>
                  <a
                    className="close"
                    onClick={() =>
                      providerUseAffilliated?.closeAffiliatedValue()
                    }
                  >
                    <span className="icon-close-pop"></span>
                  </a>
                </div>
              </Modal.Header>
              <Modal.Body className="withMinHeight">
                <AffiliatedInsider
                  {...providerUseAffilliated}
                  setRoleAffiliated={(role: string, idUser: string) =>
                    providerUseAffilliated?.setRoleAffiliated({ role, idUser })
                  }
                />
              </Modal.Body>
            </Modal>
            {/* END POPUP Affiliated insider */}

            {/* Modal update profile image */}
            <ModalPhoto {...providerUseModalPhoto} />
            <PlanDetails {...providerUseManageCompanyAndBilling} />
            <Footer />
          </div>
        )}
    </>
  );
}
