/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import logoLettre from "../../../resources/images/logo-lettre.svg";
import useWelcomeCtr from "../../../controller/use-welcom-ctr";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { rootState } from "../../../redux/reducers";
import { logout } from "../../../redux/actions/acounts-action";
import UseNavigation from "../../../hooks/use-navigate";
import { fileUrl } from "../../../utils/index";
import { useDataContext } from "../../../context/index";
import BlockNotif from "../../widget/block-notif/index";
import PermissionMatching from "../permission-matching/index";
import UsePersmission from "../permission-matching/use-persmission";

import Modal from "react-bootstrap/Modal";
import { BlockMessage } from "../bloc-message-notif";
import { Link } from "react-router-dom";
import Alert from "../../widget/alert-message/info";
import { MaMatchmakingUrl } from "../../../service/constant";
import { useKeycloak } from "@react-keycloak/web";


export default function Header(props: any) {
  const { getStarted } = useWelcomeCtr();
  const { keycloak } = useKeycloak();
  const userState = useSelector((state: rootState) => state.userReducer);
  const investorPage = useSelector(
    (state: rootState) => state.companyInvestorPageReducer
  );

  const [modalBlocage, setModalBlocage] = useState<boolean>(false);

  const [modalBlockedFeature, setModalBlockedFeatured] =
    useState<boolean>(false);

  const allText = [
    "Oops...",
    "",
    'Please publish your investor page to access the "Find ISVs" matchmaking feature.',
  ];
  const providerUsePersmission = UsePersmission(allText);
  const dispatch = useDispatch();
  const navigationProps = { ...UseNavigation() };
  const history = useHistory();
  const { stopEvtSource } = useDataContext();

  const urlPath = window.location.href;
  var valueofSubmenu: any;
  urlPath.indexOf("profil/inv") !== -1
    ? (valueofSubmenu = "profil/inv")
    : urlPath.indexOf("isv/match") !== -1
    ? (valueofSubmenu = "isv/match")
    : urlPath?.indexOf("community") !== -1
    ? (valueofSubmenu = "community")
    : urlPath?.indexOf("classified-ads") !== -1
    ? (valueofSubmenu = "classified-ads")
    : urlPath?.indexOf("isv/profil-locked") !== -1
    ? (valueofSubmenu = "isv/profil-locked")
    : (valueofSubmenu = "neant");

  const goToInvestorPage = () => {
    navigationProps.navigate("/profil/investor");
  };

  const goToBillings = () => {
    history.push("/settings", {
      tabToShow: "billing",
    });
  };

  const cb = async (params: boolean) => {
    if (params) {
      stopEvtSource();
      await keycloak.logout({redirectUri:"/"});
      // history.push("/signin");
    }
  };
  const disconnect = async () => {
    dispatch(logout(cb));
  };

  const goToCommunityHome = () => {
    history.push("/community/home");
  };

  const goToHomeAlternative = () => {
    history.push("/alternative-financing/home");
  };
  const goTomanda = () => {
    history.push("/ma/home");
  };

  const goToFindIsv = () => {
    console.log("Atooo");
    const isPublished = investorPage.status;
    const isAnonyme = !investorPage.isv_find_me;
    if (isPublished || isAnonyme) {
      history.push("/isv/match");
    } else {
      Alert({
        title: "Find ISVs",
        secondTitle: "You cannot access this page...",
        txt: [
          "To use the matchmaking feature to identify and contact ISVs,",
          " you need to publish your investor page.",
        ],
        btnTxt: "Set up and publish now",
        urlBtn: goToInvestorPage,
      });
    }
  };

  const goToFindMore = () => {
    const isPublished = investorPage.status;
    const isAnonyme = !investorPage.isv_find_me;
    if (isPublished || isAnonyme) {
      history.push("/isv/find-more");
    } else {
      Alert({
        title: "Find ISVs",
        secondTitle: "You cannot access this page...",
        txt: [
          "To use the matchmaking feature to identify and contact ISVs,",
          " you need to publish your investor page.",
        ],
        btnTxt: "Set up and publish now",
        urlBtn: goToInvestorPage,
      });
    }
  };

  const showConversation = () => {
    history.push("/contact/discussion");
  };

  return (
    <div className="principalMenu">
      <div className="container mx-auto">
        <PermissionMatching {...providerUsePersmission} />
        <div className="leftItemHeader">
          <div className="logoHeader">
            <img
              src={logoLettre}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/")}
            />
          </div>
          <div className="menuDynamic tl">
            <div className="item">
              <a
                href="javascript:;"
                className={` ${
                  valueofSubmenu === "isv/match" ||
                  valueofSubmenu === "isv/profil-locked"
                    ? "active"
                    : ""
                } linkHeader borRight `}
              >
                Find ISVs
              </a>
              <div className="submenu">
                <div className="title">
                  <div className="container mx-auto">
                    <span>Find ISVs</span>
                    Identify and contact software vendors (ISVs) that meet your
                    target audience
                  </div>
                </div>
                <div className="container mx-auto">
                  <a
                    className="linkHeader"
                    href="javascript:;"
                    onClick={(e) => {
                      e.preventDefault();
                      goToFindIsv();
                    }}
                  >
                    <span className="name">ISV matchmaking</span>
                    <p>Match with ISVs based on your preferences.</p>
                  </a>
                  <a
                    className="linkHeader"
                    href="javascript:;"
                    onClick={(e) => {
                      e.preventDefault();
                      goToFindMore();
                    }}
                  >
                    <span className="name">Find more</span>
                    <p>Tap into our network of ISVs outside of Devinsider.</p>
                  </a>

                  <Link to="/profil/investor">
                    <span className="name">Investor page</span>
                    <p>
                      Manage your investor page and set up your matchmaking
                      criteria.
                    </p>
                  </Link>
                </div>
              </div>
            </div>

            <div className="item">
              <Link
                to="/community/home"
                className={`${
                  valueofSubmenu === "community" ? "active" : ""
                } parent linkHeader borRight `}
              >
                Community
              </Link>
              <div className="submenu">
                <div className="title">
                  <div className="container mx-auto">
                    <span onClick={() => goToCommunityHome()}>Community</span>
                    Join a global community for software publishers (ISVs).
                  </div>
                </div>
                <div className="container mx-auto">
                  <Link
                    className="linkHeader"
                    to="/community/discussions-categories"
                  >
                    <span className="name">Forum discussions</span>
                    <p>
                      Discuss the business challenges of your software company
                      with peers and industry experts.
                    </p>
                  </Link>
                  <Link
                    className="linkHeader"
                    to="/community/article-category/0/0"
                  >
                    <span className="name">Articles</span>
                    <p>
                      Share your expertise and insights to help software
                      publishers with high-value content.
                    </p>
                  </Link>
                  <Link className="linkHeader" to="/events/all-events">
                    <span className="name">Events</span>
                    <p>
                      Browse high-value online and offline events dedicated to
                      software publishers.
                    </p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="item">
              <Link
                to={{
                  pathname: `${
                    userState?.token ? "/ma/home" : "/landing-find-inv"
                  }`,
                }}
                className="linkHeader"
              >
                Corporate development
              </Link>

              <div className="submenu">
                <div className="title">
                  <div className="container mx-auto">
                    <span>Corporate development</span>
                    Access a curated pool of investors that are looking for
                    software companies to invest in.
                  </div>
                </div>
                <div className="container mx-auto">
                  <a
                    href="javascript:;"
                    onClick={(e) => {
                      e.preventDefault();
                      goTomanda();
                    }}
                    className="linkSubMenu linkHeader"
                  >
                    <span className="name">M&A ads</span>
                    <p>
                      Find exit opportunities among Devinsider’s global
                      community of software vendors and institutional investors.
                    </p>
                  </a>
                  <a
                    href="javascript:;"
                    onClick={(e) => {
                      e.preventDefault();
                      goToHomeAlternative();
                    }}
                    className="linkSubMenu linkHeader"
                  >
                    <span className="name">Alternative financing ads</span>

                    <p>
                      Identify financing options as an alternative to standard
                      equity investments and loans.
                    </p>
                  </a>
                  <a 
                      href={MaMatchmakingUrl} 
                      className="linkSubMenu linkHeader"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="name">M&A Matchmaking</span>
                      <p>
                        Match with buyers and sellers for M&A opportunities globally. 
                      </p>
                    </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rightItemHeader">
          {keycloak && keycloak.token ? (
            <div className="menuDynamic tr">
              <div
                className="item"
                onClick={(e: any) => {
                  showConversation();
                }}
              >
                <BlockMessage unread={props.unread} />
              </div>
              <BlockNotif />
              <div className="item">
                <a href="javascript:;" className="parent linkHeader">
                  <div className="profilImg">
                    <img
                      alt="Profile"
                      src={`${fileUrl(userState.file_name)}`}
                    />
                  </div>
                  <span className="mobile">My account</span>
                </a>
                <div className="submenu">
                  <Link to="/profil">Profile</Link>
                  <Link to="/profil/investor">Investor page</Link>
                  <Link to="/settings">Account settings</Link>
                  <a
                    href="javascript:;"
                    onClick={(e) => {
                      e.preventDefault();
                      goToBillings();
                    }}
                  >
                    Billing center
                  </a>
                  <a
                    href="javascript:;"
                    onClick={(e) => {
                      e.preventDefault();
                      disconnect();
                    }}
                  >
                    Sign out
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <div className="menuDynamic tr notConnected">
              <div className="item">
                <Link to="/signin" className="signinHeader">
                  Sign in
                </Link>
              </div>
              <div className="item">
                <a
                  href="javascript:;"
                  className="getstartedHeader"
                  onClick={(e) => {
                    e.preventDefault();
                    getStarted();
                  }}
                >
                  Get started
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal show={modalBlocage} className="small forDesactivate popPers">
        <Modal.Header>
          <div className="headerpermission noStep">
            <h2 className="title">Find ISVs</h2>
            <a
              href="javascript:;"
              className="close"
              onClick={() => setModalBlocage(false)}
            >
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="contentBorder row m-0 p-0 desactivepopup">
            <p>
              We are currently on-boarding ISVs for matchmaking and will release
              this feature soon. In the meantime check out <br />
              the{" "}
              <a
                href="javascript:;"
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/community/home");
                }}
              >
                community
              </a>{" "}
              where you can engage with ISVs and share your expertise.
            </p>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalBlockedFeature}
        className="medium forDesactivate popPers"
      >
        <Modal.Header>
          <div className="headerpermission noStep">
            <h2 className="title">Find ISVs</h2>
            <a
              href="javascript:;"
              className="close"
              onClick={() => setModalBlockedFeatured(false)}
            >
              <span className="icon-close-pop"></span>
            </a>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="contentBorder row m-0 p-0 desactivepopup">
            <p>
              You cannot use this featured while your page remain unpublished,
              go to{" "}
              <a
                href="javascript:;"
                onClick={() => {
                  setModalBlockedFeatured(false);
                  goToInvestorPage();
                }}
              >
                setting
              </a>{" "}
              and <b>publish</b> your page to see the matching result.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
