/**
 ***********************************************************
 *
 * Import for React Lib
 *
 ***********************************************************/
import React, { Suspense, useEffect, useRef, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { getCompletedAcccount } from "./service/applicatif/acount";
// @ts-ignore
import { EventSourcePolyfill } from "event-source-polyfill";
// @ts-ignore
import sound from "./resources/images/notif_message.wav";

/************************************************************
 *
 * Import for Project Module
 *
 ***********************************************************/
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { DataContext } from "./context";
import { Imessage, ItemDiscussion } from "./controller/tchat-ctr/type";
import PublicRoute from "./hooks/PublicRoute";
import NotAllowedConnectedPage from "./hooks/notAllowedConnectedPage";
import PrivateRoute from "./hooks/private-route";
import store, { persistor } from "./redux/store";
import routes from "./routes/index";
import { LoadingAllPage } from "./screen/widget/loader";
import { baseUrlMercure } from "./service/constant";
import "./style.scss";
import { topicTypeStream } from "./utils";
import { detectBrowser } from "./service/api";

require("dotenv").config();
function App() {
  const [filePictureReseller, setFilePictureReseller] = useState<File>();
  const [fileLogoreReseller, setFileLogoReseller] = useState<File>();
  const [filePictureOem, setFilePictureOem] = useState<File>();
  //all topics need
  const [newConversation, setNewConversation] = useState<ItemDiscussion>();
  const [newStreamMessage, setNewMessage] = useState<Imessage>();
  const [newParticipants, setNewParticipants] = useState<any>();
  const [newNotifMessage, setNewNotifMessage] = useState<number>(0);
  const [nbrRequest, setNbrRequest] = useState<any>();

  const [appLoaded, setAppLoaded] = useState<boolean>(false);
  const [geoRestricted, setGeoRestricted] = useState<boolean>();
  const [refreshMessageNotif, setRefreshMessageNotif] =
    useState<boolean>(false);
  const [newFilesInConversation, setNewFilesInConversation] = useState<{
    file: any;
    conversation_id: number;
  }>();
  const [isFirefox, setIsFirefox] = useState(false);

  const handleConversation = (conv: any | null) => {
    setNewConversation(conv);
  };

  const handleNewNotifMess = (notif: any | null) => {
    setNewNotifMessage(notif);
  };

  const handleNbrRequest = (notif: number | null) => {
    console.log(notif);
    setNbrRequest(notif);
  };

  const handleMessage = (message: any | null) => {
    setNewMessage(message);
    setRefreshMessageNotif(true);
  };

  const handleNewParticipantOem = (representatif: any) => {
    setNewParticipants(representatif);
  };

  const handleNewFiles = (p: { file: any; conversation_id: number }) => {
    setNewFilesInConversation(p);
  };

  var evtSource: any = null;

  const refAudio: any = useRef();

  const getData = async () => {
    let result: any = await getCompletedAcccount();
    if (result && result?.data) {
      sessionStorage.setItem("completed_account", JSON.stringify(result.data));
    }

    return result;
  };

  useEffect(() => {
    const browser = detectBrowser();
    setIsFirefox(false);
    // setIsFirefox(browser === "Firefox");

    getData()
      .then(() => setGeoRestricted(false))
      .catch((error) => {
        if (error.response) {
          if (
            error.response.status === 403 &&
            error.response.data === "Geo restricted"
          ) {
            setGeoRestricted(true);
          } else {
            setGeoRestricted(false);
          }
        } else {
          setGeoRestricted(false);
        }
      })
      .finally(() => {
        setAppLoaded(true);
      });

    return () => {};
  }, []);

  const startEvtSource = () => {
    let objectUrl = new URL(baseUrlMercure);

    const allTopics = store?.getState().userReducer?.token_notification || [];

    for (const topic of allTopics) {
      objectUrl.searchParams.append("topic", topic);
    }

    evtSource = new EventSourcePolyfill(objectUrl, {
      headers: {
        Authorization: "Bearer " + store?.getState().userReducer?.token_mercure,
      },
    });

    evtSource.onmessage = (event: { data: string }) => {
      const pathName = window?.location?.pathname;

      const isInMessageScreen = pathName === "/contact/discussion";

      let stream: { payload: any; type: string; conversation_id: number } =
        JSON.parse(event?.data);

      if (
        stream?.type === topicTypeStream?.newConversation &&
        isInMessageScreen
      ) {
        let dataTopics = JSON.parse(stream.payload);
        let sender = dataTopics?.last_message?.[0].sender?.id;
        if (
          dataTopics?.is_muted === false &&
          sender !== store?.getState().userReducer?.id
        ) {
          startAudio();
        }
        handleConversation(dataTopics || null);
      }

      if (stream?.payload) {
        if (stream?.type === topicTypeStream.newMessage && isInMessageScreen) {
          handleMessage(JSON.parse(stream.payload) || null);
        }

        if (stream?.type === topicTypeStream.notifMess) {
          if (stream.payload?.list_filter_category) {
            const { list_filter_category } = stream.payload;

            const {
              COMMUNITY,
              FIND_ISV,
              CORPORATE_DEVELOPMENT,
              MARKETPLACE,
              NUMBER_REQUEST,
              STRATEGIC_PARTNERSHIPS,
            } = list_filter_category;

            const totalUnread =
              (COMMUNITY || 0) +
              (MARKETPLACE || 0) +
              (CORPORATE_DEVELOPMENT || 0) +
              (FIND_ISV || 0) +
              (STRATEGIC_PARTNERSHIPS || 0);
            handleNewNotifMess(totalUnread);
            handleNbrRequest(NUMBER_REQUEST);
          }
        }

        if (
          stream?.type === topicTypeStream.add_particapant_oem &&
          isInMessageScreen
        ) {
          handleNewParticipantOem(JSON.parse(stream.payload) || null);
        }
        if (
          stream?.type ===
            topicTypeStream?.new_file_add_in_current_conversation &&
          isInMessageScreen
        ) {
          let file = JSON.parse(stream.payload) || [];
          let idConv = stream?.conversation_id || 0;
          handleNewFiles({ file, conversation_id: idConv });
        }
      }
    };

    evtSource.onopen = () => {
      let hasToken = store?.getState().userReducer.token;
      if (hasToken === "" || !hasToken) {
        evtSource.close();
      }
    };

    evtSource.onerror = () => {
      let hasToken = store?.getState()?.userReducer?.token;
      if (hasToken === "" || !hasToken) {
        evtSource.close();
      }
    };
  };

  const stopEvtSource = () => {
    if (evtSource) {
      evtSource && evtSource.close();
    }
  };

  const startAudio = () => {
    if (refAudio.current) {
      refAudio.current.volume = 0.2;
      const playPromise = refAudio?.current?.play();
      if (playPromise !== undefined) {
        playPromise.then((_: any) => {}).catch(() => {});
      }
    }
  };

  useEffect(() => {
    const checkEvetSourceStatus =
      !evtSource || evtSource?.readyState !== EventSource.CONNECTING;

    if (checkEvetSourceStatus) {
      startEvtSource();
    }
    return () => {};
  }, []);

  return (
    <>
      {isFirefox && (
        <div
          id="testd"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "transparent",
            zIndex: 9999,
          }}
        ></div>
      )}
      <Suspense fallback={<LoadingAllPage />}>
        {!appLoaded && <LoadingAllPage />}
        {appLoaded && !geoRestricted && (
          <>
            <ToastContainer autoClose={2000} />
            <DataContext.Provider
              value={{
                filePictureReseller: filePictureReseller,
                fileLogoreReseller: fileLogoreReseller,
                filePictureOem: filePictureOem,

                name: "",
                email: "",
                setFilePictureReseller,
                setFileLogoReseller,
                setFilePictureOem,
                newStreamConv: newConversation,
                handleConversation,
                setRefreshMessageNotif,
                refreshMessageNotif,
                newStreamMessage,
                handleMessage,
                handleNewParticipantOem,
                newParticipants,
                handleNewFiles,
                newFilesInConversation,
                newNotifMessage,
                nbrRequest,
                stopEvtSource: stopEvtSource,
                startEvtSource: startEvtSource,
                evtSource: evtSource,
              }}
            >
              <div style={{ display: "none" }}>
                <audio ref={refAudio} src={sound} />
              </div>
              <Provider store={store}>
                <PersistGate persistor={persistor}>
                  <Router>
                    <Switch >
                      {routes?.map((route: any) =>
                        route.guard === "public" ? (
                          <PublicRoute
                            key={route.path}
                            path={route.path}
                            component={route.component}
                            exact={route.exact}
                            allowedRole={route?.allowedRole}
                          />
                        ) : route.guard === "not-logged-page" ? (
                          <NotAllowedConnectedPage
                            key={route.path}
                            path={route.path}
                            component={route.component}
                            exact={route.exact}
                            allowedLoggedUser={route.allowedLoggedUser}
                          />
                        ) : (
                          <PrivateRoute
                            key={route.path}
                            path={route.path}
                            component={route.component}
                            exact={route.exact}
                            allowedRole={route.allowedRole}
                          />
                        )
                      )}
                    </Switch>
                  </Router>
                </PersistGate>
              </Provider>
            </DataContext.Provider>
          </>
        )}
        {appLoaded && geoRestricted && (
          <>
            <h1>Forbidden</h1>
            This site is not available in your country.
          </>
        )}
      </Suspense>
    </>
  );
}

export default App;
//https://medium.com/jspoint/typescript-interfaces-4a2af07c8070
