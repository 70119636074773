import React, { useState, useEffect } from "react";
import logoLettre from "../../../resources/images/logo-lettre.svg";
import { useHistory } from "react-router";
import useWelcomeCtr from "../../../controller/use-welcom-ctr";
import { useSelector, useDispatch } from "react-redux";
import { rootState } from "../../../redux/reducers";
import { logout } from "../../../redux/actions/acounts-action";
import { fileUrl } from "../../../utils";
import { useDataContext } from "../../../context/index";
import BlockNotif from "../../widget/block-notif/index";
import { BlockMessage } from "../bloc-message-notif";
import { Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";

export default function Header(props: any) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const { keycloak } = useKeycloak();
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  const { stopEvtSource } = useDataContext();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const userState = useSelector((state: rootState) => state.userReducer);
  const { getStarted, goLogin } = useWelcomeCtr();
  const history = useHistory();
  const dispatch = useDispatch();

  const cb = async (params: boolean) => {
    if (params) {
      stopEvtSource();
      await keycloak.logout({redirectUri:"/"});
      // history.push("/signin");
    }
  };
  const logOut = () => {
    dispatch(logout(cb));
  };

  const showDiscussion = () => {
    history.push("/contact/discussion");
  };

  const goToHomeAlternative = () => {
    history.push("/alternative-financing/home");
  };
  return (
    <div className="principalMenu">
      <div className="container mx-auto">
        <div className="leftItemHeader">
          <div className="logoHeader">
            <img
              src={logoLettre}
              alt=""
              onClick={() => history.push("/")}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="menuDynamic tl">
            <div className="item hidden">
              <a href="javascript:;" className="linkHeader parent">
                Marketplace
              </a>
              <div className="submenu">
                <div className="title">
                  <div className="container mx-auto">
                    <span>Marketplace</span>
                    Identify partnership opportunities with resellers, investors
                    and other professionals working in the software industry.
                  </div>
                </div>
                <div className="container mx-auto"></div>
              </div>
            </div>
            <div className="item">
              <a href="javascript:;" className={`parent linkHeader`}>
                Corporate development
              </a>
              <div className="submenu">
                <div className="title">
                  <div className="container mx-auto">
                    <span>Corporate development</span>
                    Manage your corporate development needs along all stages of
                    the equity life cycle.
                  </div>
                </div>
                <div className="container mx-auto">
                  <a
                    href="javascript:;"
                    onClick={() => goToHomeAlternative()}
                    className="linkSubMenu linkHeader"
                  >
                    <span className="name">Alternative financing</span>
                    <p>
                      Fund your business leveraging alternative solutions to
                      standard equity investments and loans.
                    </p>
                  </a>

                  <Link to="/ma/home" className="linkSubMenu linkHeader">
                    <span className="name">M&A</span>

                    <p>
                      Find exit opportunities among Devinsiderʼs global
                      community of software vendors and institutional investors.
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rightItemHeader">
          {keycloak && keycloak.token? (
            <div className="menuDynamic tr">
              <div
                className="item"
                onClick={(e: any) => {
                  showDiscussion();
                }}
              >
                <BlockMessage unread={props.unread} />
              </div>
              <BlockNotif />
              <div className="item">
                <a href="javascript:;" className="parent linkHeader">
                  <div className={`profilImg`}>
                    <img
                      alt="Profile"
                      src={`${fileUrl(userState.file_name)}`}
                    />
                  </div>
                  <span className="mobile">My account</span>
                </a>
                <div className="submenu">
                  <Link to="/profil">Profile</Link>
                  <Link to="/settings">Account settings</Link>
                  <a href="javascript:;" onClick={() => logOut()}>
                    Sign out
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <div className="menuDynamic tr notConnected">
              <div className="item">
                <a
                  href="javascript:;"
                  className="signinHeader"
                  onClick={(e) => {
                    e.preventDefault();
                    goLogin();
                  }}
                >
                  Sign in
                </a>
              </div>
              <div className="item">
                <a
                  href="javascript:;"
                  className="getstartedHeader"
                  onClick={(e) => {
                    e.preventDefault();
                    getStarted();
                  }}
                >
                  Get started
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
